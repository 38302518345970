<template>
  <div class="mx-cover">
    <div class="mx-cover-before" v-show="modalVisible === false">
      <img v-if="image !== null" @click="modalVisible = true" class="mx-cover__image" :src="image" alt="user avatar">
    </div>
    <div class="mx-cover-after" v-show="modalVisible === true">
      <div v-show="croppieView" class="mx-cover__bg" id="croppieId"></div>
      <div class="file-input mx-cover__bg">
        <div class="mx-cover__btns">
          <div>
            <button v-show="croppieView" class="mx-btn-info" @click="uploadFile">
              {{ $t('user.cover.success') }}
            </button>
            <button v-show="croppieView" class="mx-btn-default" @click="abrechen">
              {{ $t('user.cover.abort') }}
            </button>
          </div>
          <label style="width: 100%;" for="upload-image" class="mx-btn mx-btn-outline-default">
            <i class="fa fa-cloud-upload"></i>
            {{ !croppieView ? $t('user.cover.select_avatar') : $t('user.cover.other_avatar')  }}
          </label>
        </div>
        <input type="file" id="upload-image" v-on:change="setUpFileUploader">
      </div>
    </div>
  </div>
</template>

<script>

  import Croppie from "croppie"
  import options from "../../options";

  export default {
    props: ['pref', 'defaultImage'],
    data() {
      return {
        croppie: null,
        image: null,
        default_url: options.default_url,
        // default_image: '/assets/images/avatar.jpg',
        default_image: this.getDefaultImage(),
        modalVisible: false,
        croppieView: false,
        cover_edit:false
      }
    },
    mounted() {
      this.image = this.pref;
      this.setUpCroppie();

      this.$on("imageUploaded", function (imageData) {
        this.image = imageData;
        this.croppie.destroy();
        this.setUpCroppie(imageData);
      });

      if (this.image === null) {
        this.image = this.default_image;
      }
      this.test();
    },
    methods: {
      getDefaultImage() {
        if (this.defaultImage === null) {
          return '/assets/images/avatar.jpg'
        }
        return options.avatar_url + this.defaultImage
      },

      test() {

        // const w = 531;
        // const h = 709;

        const w = 531; // шаблон картинки ширина
        const h = 709;  // шаблон картинки высота

        const hr = 300; // предпологаемая высота картинки
        const wr = 800; // предпологаемая ширена картинки

        let result_h = w/(h/hr) ;
        let result_w = hr/(wr/w);

       // console.log(Math.round(result_h)) // ширина картинки

      },

      abrechen() {
        this.modalVisible = false
        this.image = this.default_image;
        this.$emit('cover', {cover: null, cover_edit: this.cover_edit })
      },
      setUpCroppie() {
        let el = document.getElementById("croppieId");

        this.croppie = new Croppie(el, {
          viewport: {
            height: 300,
            width: 225,
            type: 'square'
          },
          boundary: {
            height: 300,
            width: 225
          },
          showZoomer: true,
          enableOrientation: true,
          mouseWheelZoom: 'ctrl'
        });

        this.croppie.bind({
          url: this.image,
        });

      },

      setUpFileUploader(e) {
        let files = e.target.files || e.dataTransfer.files;

        if (!files.length) {
          return
        }

        this.createImage(files[0]);
        this.croppieView = true;
      },

      createImage(file) {
        this.image = new Image();
        let reader = new FileReader();

        reader.onload = (e) => {
          this.image = e.target.result;
          this.$emit("imageUploaded", e.target.result);
        };

        reader.readAsDataURL(file);
      },

      uploadFile() {
        this.croppie.result({type: 'canvas', size: 'viewport'}).then((response) => {
          this.image = response;
          this.cover_edit = true;

          this.$emit('cover', {cover: response, cover_edit: this.cover_edit })
          setTimeout(this.modalVis, 100)
        });
      },
      modalVis() {
        this.modalVisible = false;
      }
    }
  }
</script>

<style lang="scss">

  .mx-cover {
    background: #f5f5f5;
    width: 225px;
    min-height: 300px;

    &-after {
      display: flex;
      flex-direction: column;
      //background: red;
      min-height: 300px;
      justify-content: center;
      .mx-cover__btns {
        text-align: center;
         & > div {
           display: flex;
           .mx-btn-info {
             padding: 10px 18px;
             //background: #285457;
             background: #cccccc;
             border-right: 1px solid #b6b6b6;
             width: 40%;
           }
           .mx-btn-default {
             padding: 10px 18px;
             background: #cccccc;
             width: 60%;
           }
        }
        .mx-btn-outline-default {
          background: #cccccc;
          border-top: 1px solid #b6b6b6;
          border-bottom: 1px solid #b6b6b6;
          color: #333;
          padding: 10px 18px;
          //border-radius: 4px;
          font-weight: 500;
          display: inline-block;
        }
      }
    }
  }

  .Image-upload-wrapper.Image-upload {
    justify-content: center;
  }

  .file-input {
    display: block;
    width: 100%;

    label {
      cursor: pointer;
      width: 100%;
      text-align: center;
    }

    input[type="file"] {
      display: none;
    }

    .custom-file-upload {
      border: 1px solid #ccc;
      border-radius: 4px;
      display: inline-block;
      padding: 6px 12px;
      cursor: pointer;
    }

  }

  .croppie-container {
    height: auto;
  }

</style>
