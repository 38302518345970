<template>
  <v-container fluid style="height: calc(100vh - 48px)">
    <mx-preloader :loader-status="preloader"/>
    <v-row class="mt-4">
      <v-col class="d-flex">
        <v-btn
            style="padding: 24px 0 25px 0;"
            class="mr-7"
            elevation="2"
            color="primary"
            medium
            :to="{name: 'employees'}"
        >
          <v-icon size="32">mdi-chevron-left</v-icon>
        </v-btn>
        <h1> {{ $t('employee.edit.title') }}</h1>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            class="ml-auto"
            elevation="2"
            color="success"
            medium
            @click="employeeEdit"
            v-if="mxware.can('employee-update')"
        >
          <v-icon size="28">mdi-content-save-outline</v-icon>
          <!--          {{ $t('customer.buttons.save') }}-->
        </v-btn>
        <v-btn
            style="padding-top: 24px; padding-bottom: 24px;"
            class="ml-1"
            elevation="2"
            color="red"
            dark
            medium
            @click="dialog = true"
            v-if="mxware.can('employee-delete')"
        >
          <v-icon size="28">mdi-trash-can-outline</v-icon>
          <!--          {{ $t('customer.buttons.delete') }}-->
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="8" md="8">
        <v-card>
          <v-card-title>
            {{ $t('employee.titles.personal_data') }}
          </v-card-title>
          <v-card-text>
            <v-form class="pa-4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.first_name"
                                outlined
                                hide-details
                                :label="$t('employee.first_name')"
                                :placeholder="$t('employee.first_name')"
                                required
                                :error="errors.first_name!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.last_name"
                                outlined
                                hide-details
                                :label="$t('employee.last_name')"
                                :placeholder="$t('employee.last_name')"
                                required
                                :error="errors.last_name!==undefined"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select v-model="form.gender"
                            :label="$t('employee.gender.title')"
                            :items="gender_select"
                            outlined
                            hide-details
                            :append-icon="'mdi-chevron-down'"
                            :menu-props="{ bottom: true, offsetY: true }"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.birthday"
                                outlined
                                hide-details
                                type="date"
                                :label="$t('employee.birthday')"
                                :placeholder="$t('employee.birthday')"
                                required
                                :error="errors.birthday!==undefined"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.email"
                                outlined
                                hide-details
                                type="email"
                                :label="$t('employee.email')"
                                :placeholder="$t('employee.email')"
                                required
                                :error="errors.email!==undefined"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="form.position"
                                outlined
                                hide-details
                                :label="$t('employee.position')"
                                :placeholder="$t('employee.position')"
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="4">
        <v-card class="mb-6">
          <v-card-title>{{ $t('employee.titles.settings') }}</v-card-title>
          <v-card-text>
            <!--            <v-switch-->
            <!--                v-model="form.email_send"-->
            <!--                :label="$t('customer.email_send')"-->
            <!--                color="success"-->
            <!--            ></v-switch>-->
            <v-switch
                v-model="form.is_deleted"
                :label="$t('employee.is_deleted')"
                color="success"
            ></v-switch>
            <v-switch
                v-model="form.is_blocked"
                :label="$t('employee.is_blocked')"
                color="success"
            ></v-switch>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>
            <div class="d-flex justify-lg-space-between" style="width: 100%;">
              <span>{{ $t('employee.titles.avatar') }}</span>
              <span v-if="mxware.can('employee-update')" @click.prevent="avatarDelete">
                <v-icon v-if="avatarButtonDelete()" color="red" style="cursor: pointer;" size="24">
                  mdi-trash-can-outline
                </v-icon>
              </span>
            </div>
          </v-card-title>
          <v-card-text class="d-flex align-center justify-center" style="min-height: 330px">
            <UserCover
                v-if="cover_active"
                :pref="(form.avatar === null ? '/assets/images/avatar.jpg' : options.avatar_url + form.avatar)"
                @cover="addCover"
                :defaultImage="form.avatar"
                ref="userCoverComponent"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="500"
      >
        <v-card class="pa-4">
          <v-card-title class="headline mb-9">
            <h3 style="text-align: center">
              {{ $t('employee.titles.employee_delete') }}
            </h3>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="red" dark @click="employeeDelete">
              {{ $t('employee.buttons.delete') }}
            </v-btn>
            <v-btn color="grey" dark @click="dialog = false">
              {{ $t('employee.buttons.abort') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>

import UserCover from "@/components/Image/UserCover";

export default {
  components: {UserCover},
  data() {
    return {
      preloader: true,
      dialog: false,
      cover_active: false,
      cover_edit: false,
      check_avatar: null,
      avatar_button_delete: false,
      form: {
        avatar: null,
        cover_edit: false,

        identification: this.$t('employee.create.identification'),
        position: '',
        first_name: '',
        last_name: '',
        email: '',
        birthday: '',
        gender: 'female',
        is_deleted: 0,
        is_blocked: 0,
        email_send: 0,
      },
      gender_select: [
        {
          text: this.$t('employee.gender.female'),
          value: 'female'
        },
        {
          text: this.$t('employee.gender.male'),
          value: 'male'
        },
        {
          text: this.$t('employee.gender.divers'),
          value: 'divers'
        },
        {
          text: this.$t('employee.gender.indefinite'),
          value: 'indefinite'
        },
      ],
      errors: {},
    }
  },
  mounted() {
    this.$store.dispatch('employeeEdit', this.$route.params.id).then(() => {
      this.form = this.$store.getters.employee
      this.form.user_id = this.$store.getters.user.id
      this.form.user_full_name = this.$store.getters.user.first_name + ' ' + this.$store.getters.user.last_name

      this.check_avatar = this.$store.getters.employee.avatar
      this.cover_active = true
      this.preloader = false

      this.avatar_button_delete = this.form.avatar !== null
    })
  },
  methods: {
    avatarButtonDelete() {
      return this.form.avatar !== null && this.avatar_button_delete === true
    },
    avatarDelete() {
      this.cover_active = false
      this.$store.dispatch('employeeAvatarDelete', this.form).then(() => {
        this.form.avatar = '/images/avatar.jpg';
        this.cover_active = true
        this.cover_edit = false;
        this.form.cover_edit = false
        this.avatar_button_delete = false
      })
    },
    addCover(data) {
      this.form.avatar = (data.cover !== '/images/avatar.jpg') ? data.cover : null;
      this.cover_edit = data.cover_edit;
      this.form.cover_edit = data.cover_edit
    },
    getPassword(password) {
      this.form.password = password
    },
    employeeEdit() {
      this.$store.dispatch('employeeUpdate', this.form).then(() => {
        this.$router.replace({
          name: 'employees'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    },
    employeeDelete() {
      this.$store.dispatch('employeeDelete', this.form.id).then(() => {
        this.$router.replace({
          name: 'employees'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;
        }
      })
    }
  }
}
</script>
